/* eslint-disable no-undef, no-param-reassign, no-useless-escape */

(function () {
	const uitop = {

		// all widgets stored here
		widgets: {},

		/**
		 * set config for widgets
		 * @param {String} widget - name of widget
		 * @param {String} id - config id of widget - use id=false to set options for all widgets
		 * @param {Object} values - key value pairs with options
		 */
		set(widget, id, values) {
			values = values || {};
			if (!ui.widgets[widget]) {
				ui.widgets[widget] = {};
			}
			if (!ui.widgets[widget].config) {
				ui.widgets[widget].config = {};
			}
			if (id) {
				ui.widgets[widget].config[id] = values;
			} else {
				ui.widgets[widget].config.default = values;
			}
		},

		/**
		 * ui config object
		 */
		config: {
			// enable logger
			log: false,
			// enable the new logger
			log2: false,
			errorColor: '#800',
			infoColor: '#fb0',
			configColor: '#666',
			successColor: '#080',
			xhrSuccessColor: '#0fa',
			xhrErrorColor: '#f07',
			xhrAlwaysColor: '#206',
			markColor: '#2c9c91'
		}

	};

	// make ui global
	window.ui = uitop;
}());
