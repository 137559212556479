/**
 * General settings used across the internet touchpoints of DPDHL.
 */
window.dpdhl_settings = {

	/**
	 * Retrieve the current language of the page
	 *
	 * @returns {string} The current language or 'de' as default language.
	 */
	get language() {
		const defaultLanguage = 'de';
		let language = document.getElementsByTagName('html')[0].getAttribute('lang');
		if (language === undefined || language === null) {
			// Set default language if the attribute cannot be retrieved.
			language = defaultLanguage;
			// eslint-disable-next-line no-console
			console.error('Error while retrieving language of page, therefore, setting default language');
		}
		return language;
	}

};
