/* eslint-disable */
const $ = require('jquery');
window.$ = window.jQuery;
window.dhl = {};

// DPDHL Settings
import './dpdhl.settings';

// jQuery Widget Factory
import './ui.legacy/ui.top';
